import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'


/* TODO
    -geometrie clipping wenn winkel hoch
*/

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:{
        uAlpha: { value: 1}
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


// Object
const geometry = new THREE.BoxGeometry(1, 1, 1)
const material = new THREE.MeshBasicMaterial({ color: 'grey' })
const mesh = new THREE.Mesh(geometry, material)
//scene.add(mesh)

const geometry2 = new THREE.BoxGeometry(300, 0.1, 200)
//const material2 = new THREE.MeshBasicMaterial({ color: 0xFFA500})
const material2 = new THREE.MeshPhongMaterial()
material2.color = new THREE.Color(0xFFA500)
const mesh2 = new THREE.Mesh(geometry2, material2)
mesh2.position.y = -0.51
scene.add(mesh2)
mesh2.receiveShadow = true

const geometry3 = new THREE.SphereGeometry( 200, 60, 40 );
geometry3.scale( - 1, 1, 1 );
const mesh3 = new THREE.Mesh(geometry3, material2)
scene.add(mesh3)

// Light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.6)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, 0.5)
pointLight.position.x = 0
pointLight.position.y = 3
pointLight.position.z = 0
scene.add(pointLight)
pointLight.castShadow = true

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const zoomFaktor = 3;

// camera
const aspectRatio = sizes.width / sizes.height
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 1, 1000)
//const camera = new THREE.OrthographicCamera(-zoomFaktor * aspectRatio,zoomFaktor * aspectRatio,zoomFaktor,-zoomFaktor, 0.1, 1000)
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxPolarAngle = Math.PI/4;
controls.screenSpacePanning = false;
controls.dampingFactor = 0.1;
//controls.enableRotate = false
//controls.maxZoom = 1;   //wie nah
//controls.minZoom = 0.2; // wie weit, kleiner = weiter
controls.minDistance = 15;
controls.maxDistance = 15;

const raycaster = new THREE.Raycaster()

const points = [
    {
        position: new THREE.Vector3(3.1, 1.7, 2.5),
        element: document.querySelector('.point-0')
    }
]

// LOAD
const loadingBarElement = document.querySelector('.loading-bar')
var loadReady=0;
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        }, 500)
        controls.minDistance = 7;
        window.setTimeout(() =>
        {
        points[0].element.classList.add('visible');
        loadReady=1;
        }, 5000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)

// Loader
var gltfLoader = new GLTFLoader(loadingManager)
var DracoLoader = new DRACOLoader()
DracoLoader.setDecoderPath( '/draco/' );
gltfLoader.setDRACOLoader( DracoLoader );

//const material4 = new THREE.MeshNormalMaterial()
const material4 = new THREE.MeshPhongMaterial()
//material4.wireframe = true
//material4.side = THREE.DoubleSide
var loadID = 0
var pivot = new THREE.Group();
scene.add( pivot );

gltfLoader.load(
    '/models/Moebel2.glb',
    (gltf) =>
    {
        console.log('success')
        console.log(gltf)
        loadID = gltf.scene.id;
        //console.log( gltf.scene.id)
        //gltf.scene.material = material4
        //gltf.scene.scale.set(0.1,0.1,0.1)
        //gltf.scene.scale.set(6,6,6)
        
        // zentrieren
        var box = new THREE.Box3().setFromObject( gltf.scene );
        var center = box.getCenter( new THREE.Vector3() );
        
        gltf.scene.position.x += ( gltf.scene.position.x - center.x );
        //gltf.scene.position.y += ( gltf.scene.position.y - center.y );
        gltf.scene.position.z += ( gltf.scene.position.z - center.z );
        
        //gltf.scene.position.multiplyScalar( - 1 );
        pivot.add( gltf.scene );

        //scene.add(gltf.scene)
        // scene.getObjectById(loadID).castShadow = true 
        // scene.getObjectById(loadID).position.y = 1 
        
    }
)

/*
gltfLoader.load(
    '/models/monkey.gltf',
    (gltf) =>
    {
        console.log('success')
        gltf.scene.children[2].material = material4
        loadID = gltf.scene.children[2].id;
        scene.add(gltf.scene.children[2])
        scene.getObjectById(loadID).castShadow = true 
        scene.getObjectById(loadID).position.y = 1 
        
    }
)

*/


window.addEventListener('resize', () => 
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    // aspectRatio = sizes.width / sizes.height
    // camera.left = -zoomFaktor * aspectRatio
    // camera.right= zoomFaktor * aspectRatio
    // camera.top  = zoomFaktor
    // camera.bottom = -zoomFaktor
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () => 
{
    if(!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    } 
})

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{   
    const elapsedTime = clock.getElapsedTime()
    // pivot.rotation.y = elapsedTime * Math.PI *2 *0.005
    
    //points[0].element.classList.add('visible');
    // Go through each point
    if(loadReady==1){
        points[0].element.classList.add('visible');

        $.ajax({
            url : 'index.php', // your php file
            type : 'GET', // type of the HTTP request
            success : function(data){
               var obj = jQuery.parseJSON(data);
               //console.log(obj);
               document.getElementsByClassName("point")[0].getElementsByClassName("text")[0].textContent = "Temp: " + obj[0] + " °C"
            }
         });
        // var obj = 18.0;
        //  document.getElementsByClassName("point")[0].getElementsByClassName("text")[0].textContent = "Temp: " + obj[0] + " °C"
    }
    
    for(const point of points)
    {
        const screenPosition = point.position.clone()
        screenPosition.project(camera)

        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = - screenPosition.y * sizes.height * 0.5
        point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }

    if(loadID!=0){
        // point.element.classList.add('visible')
    }
        //scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
        //scene.getObjectById(loadID).rotateOnAxis(center, elapsedTime * Math.PI *2 *0.01)
        //scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
    //console.log("JOOOOO")
        //console.log(scene.getObjectByProperty("uuid", "DD1C1258-1956-4217-81FB-088E99C52954"))
    // }
    //scene.children[5].rotation.y = elapsedTime * Math.PI *2 *0.01
    //mesh.rotation.y = elapsedTime * Math.PI *2 *0.01
    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()